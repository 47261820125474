<template>
  <suspense>
  <slider-on-main-component :items ="items"/>
    <template #fallback>
      Loading...
    </template>
  </suspense>
  <!--<popular-trainer-component/> -->
  <!--<contact-us-component id="contact-us-section"/>-->
  <!-- <info-switcher-component /> -->
</template>

<script>
import SliderOnMainComponent from "@/components/SliderOnMainComponent";


export default {
  name: "HomeView",
  components: { SliderOnMainComponent}
}
</script>

<style scoped>

</style>