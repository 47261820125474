<template>
  <header-component/>
  <div class="wrapper">
  <router-view />
 <!-- <footer-component />
  <footer-rights-component /> -->
  </div>
</template>

<script>
//import FooterComponent from "@/components/FooterMenuComponent";
//import FooterRightsComponent from "@/components/FooterRightsComponent";
import HeaderComponent from "@/components/HeaderComponent";

export default {
  name: "LandingLayout",
  components: {
    HeaderComponent,
    //FooterComponent,
  //  FooterRightsComponent
  }
}
</script>

<style lang="scss" scoped>
.wrapper{
    background: #fff;
  }
</style>