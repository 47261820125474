<template>
  <transition name="modal">
    <div class="overlay" v-if="show" @click.self="closeModal">
      <div class="modal-content">
        <div class="modal-wrapper">
          <div class="modal-body">
            <div class="video">
            <iframe width="100%" height="315" :src="video" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalVideoApplicationComponent",
  data: function (){
    return {
      show: false,
      video: null
    }
  },
  methods: {
    closeModal: function (){
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.video{
  .modal-content{
    overflow: hidden;
    .modal-wrapper{
      padding: 0;
    }
  }
}

.overlay{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: auto;
  position: fixed;
  visibility: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(24, 24, 24, 0.16);
  backdrop-filter: blur(2px);
  transition: all .3s ease-in-out;
}
  .modal-content{
    margin: auto;
    width:478px;
    height:315px;
    border-radius: 20px;

    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-shadow: 0 4px 64px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);

    .modal-wrapper{
      padding: 26px 48px 20px 48px;
    }
    .modal-cross{
      position: relative;
      cursor: pointer;
    }
    .fireworks{
      margin: auto;
      width: 100%;
      max-width: 134px;
      img{
        object-fit: contain;
        width: 100%;
      }
    }
    .caption{
      font-family: 'Raleway', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 150%;
      text-align: center;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #222E37;
      padding-top: 32px;
      padding-bottom: 4px;
    }
    .text{
      font-family: 'Raleway', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 150%;
      text-align: center;
      font-feature-settings: 'pnum' on, 'lnum' on;
      padding-top: 4px;
    }
  }
.cross {
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.cross:hover {
  opacity: 1;
}
.cross:before, .cross:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #39434B;
}
.cross:before {
  transform: rotate(45deg);
}
.cross:after {
  transform: rotate(-45deg);
}
.modal-enter-active, .modal-leave-active {
  transition: opacity 1s
}

.modal-enter, .modal-leave-to {
  opacity: 0
}

</style>