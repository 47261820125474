import { createRouter, createWebHistory } from "vue-router";

import HomeView from "@/views/HomeView";

import { loadLocaleMessages, setI18nLanguage, SUPPORT_LOCALES } from "@/i18n";

export function setupRouter(i18n) {
    const locale = i18n.mode === 'legacy' ? i18n.global.locale : i18n.global.locale.value

    //setup routes
    const routes = [
        {
            path: '/:locale/',
            name: 'HomeView',
            component: HomeView,
            meta: {
                layout: 'landing-layout',
                title: 'D.I.V.E. - платформа VR-образования',
                isPrivate: false
            }
        },
        {
            path: '/:locale/simulators',
            name: 'SimulatorsView',
            component: () => import('../views/SimulatorsView'),
            meta: {
                layout: 'landing-layout',
                title: 'Тренажеры',
                isPrivate: false
            }
        },
        {
            path: '/:locale/simulators/:id',
            name: 'SimulatorCaseView',
            component: () => import('../views/SimulatorCaseView'),
            meta: {
                layout: 'landing-layout',
                title: 'Тренажеры',
                isPrivate: false
            }
        },
        {
            path: '/:locale/simulators',
            name: 'PrivateAccountView',
            component: () => import('../views/SimulatorCaseView'),
            meta: {
                layout: 'landing-layout',
                title: 'Тренажеры',
                isPrivate: false
            }
        },
        {
            path: '/:locale/service',
            name: 'ServicesView',
            component: () => import('../views/SimulatorCaseView'),
            meta: {
                layout: 'landing-layout',
                title: 'Тренажеры',
                isPrivate: false
            }
        },
        {
            path: '/:locale/methodists',
            name: 'MethodistsView',
            component: () => import('../views/MethodistsView'),
            meta: {
                layout: 'landing-layout',
                title: 'Методисты',
                isPrivate: false
            }
        },
        {
            path: '/:locale/contacts',
            name: 'ContactsView',
            component: () => import('../views/ContactView'),
            meta: {
                layout: 'landing-layout',
                title: 'Наши контакты',
                isPrivate: false
            }
        },
        {
            path: '/:locale/developers',
            name: 'DevelopersView',
            component: () => import('../views/SimulatorCaseView'),
            meta: {
                layout: 'landing-layout',
                title: 'Разработчикам',
                isPrivate: false
            }
        },
        {
            path: '/:locale/terms',
            name: 'TermsView',
            component: () => import('../views/TermsView'),
            meta: {
                layout: 'landing-layout',
                title: 'Пользовательское соглашение',
                isPrivate: false
            }
        },
        {
            path: '/:locale/privacy',
            name: 'PrivacyView',
            component: () => import('../views/PolicyView'),
            meta: {
                layout: 'landing-layout',
                title: 'Политика конфиденциальности',
                isPrivate: false
            }
        },
        {
            path: '/:locale/cookies',
            name: 'CookiesView',
            component: () => import('../views/CookiesView'),
            meta: {
                layout: 'landing-layout',
                title: 'Как мы используем cookie',
                isPrivate: false
            }
        },
        {
            path: '/:locale/help',
            name: 'HelpView',
            component: () => import('../views/HelpView'),
            meta: {
                layout: 'landing-layout',
                title: 'Справка',
                isPrivate: false
            }
        },
        {
            path: '/:locale/faq',
            name: 'FAQView',
            component: () => import('../views/FAQView'),
            meta: {
                layout: 'landing-layout',
                title: 'Часто задаваемые вопросы',
                isPrivate: false
            }
        },
        {
            path: '/:locale/support',
            name: 'SupportView',
            component: () => import('../views/SupportView'),
            meta: {
                layout: 'landing-layout',
                title: 'Поддержка',
                isPrivate: false
            }
        },
        {
            path: '/',
            redirect: () => `/${locale}`
        },
        {
            path: '/:/pathMatch(.*)*',
            redirect: () => `/${locale}`
        }
    ]
// create router instance
    const router = createRouter({
        history: createWebHistory(),
        routes
    })


// navigation guards
    router.beforeEach(async (to, from, next) => {
        const paramsLocale = to.params.locale

        // use locale if paramsLocale is not in SUPPORT_LOCALES
        if (!SUPPORT_LOCALES.includes(paramsLocale)) {
            return next(`/${locale}`)
        }

        // load locale messages
        if (!i18n.global.availableLocales.includes(paramsLocale)) {
            await loadLocaleMessages(i18n, paramsLocale)
        }

        // set i18n language
        setI18nLanguage(i18n, paramsLocale)
        /*
        if (to.matched.some(record => record.meta.isPrivate)) {
            next('credential')
        } else {
            next()
        }
         */
        next()
    })
    return router
}


