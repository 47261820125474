<template>
  <header class="header" ref="header">
    <div class="header__wrapper">
      <div class="logo">
        <router-link :to="{ name: 'HomeView',  params: { locale: this.$route.params.locale} }" >
          <img src="@/assets/images/logo.png" alt="DIVE платформа" class="logo--full" />
        </router-link>
      </div>
      <div class="top-menu">
        <!--
        <div class="top-menu__item search-button">
          <router-link to="/">
            <img src="@/assets/images/icons/search-white.svg" alt="DIVE платформа"  />
          </router-link>
        </div>
        <div class="top-menu__item account-button">
          <router-link :to="{ name: 'PrivateAccountView',  params: { locale: this.$route.params.locale} }">
            <img src="@/assets/images/icons/user-white.svg" alt="DIVE платформа"  />
          </router-link>
        </div>
        <div class="top-menu__item language-button">
          <language-choice-component />
        </div>
        <hamburger-component @toggle-menu="menuActive = !menuActive" :active="menuActive"></hamburger-component>
        -->
      </div>

    </div>
    <div class="menu__wrapper">
      <transition @before-enter="beforeEnter" :css="false">
      <div class="navbar" v-show="menuActive"  >

        <div class="menu-title">{{ $t('menu.menu') }}</div>
        <div class="menu-items">
          <div class="navbar-nav">
            <router-link :to="{ name: 'SimulatorsView',  params: { locale: this.$route.params.locale} }" class="menu-item">{{ $t('menu.trainers') }}</router-link>
            <router-link :to="{ name: 'ServicesView',  params: { locale: this.$route.params.locale} }" class="menu-item">{{ $t('menu.services') }}</router-link>
            <router-link :to="{ name: 'MethodistsView',  params: { locale: this.$route.params.locale} }" class="menu-item">{{ $t('menu.methodists') }}</router-link>
            <router-link :to="{ name: 'ContactsView',  params: { locale: this.$route.params.locale} }" class="menu-item">{{ $t('menu.contacts') }}</router-link>
            <router-link :to="{ name: 'DevelopersView',  params: { locale: this.$route.params.locale} }" class="menu-item">{{ $t('menu.4developers') }}</router-link>
          </div>
        </div>

        <div class="menu-bottom">
          <div class="menu-bottom__middle">
            <div class="menu-bottom__contacts">

              <div class="menu-bottom__contacts__phone">
                <div class="menu-bottom-text phone-text">{{ $t('menu.call') }}:</div>
                <a href="tel:89539121200" class="menu-link">8 953 912 - 12 - 00</a>
              </div>

              <div class="menu-bottom__contacts__email">
                <div class="menu-bottom-text email-text">{{ $t('menu.write-email') }}:</div>
                <a href="mailto:dive@tpu.ru" class="menu-link">dive@tpu.ru</a>
              </div>
            </div>
            <div class="menu-bottom__contacts__contact-us">
              <a href="#contact-us-section" class="btn btn-common">{{ $t('make-request') }}</a>
            </div>
          </div>
          <div class="menu-bottom__footer">
            <router-link :to="{ name: 'TermsView',  params: { locale: this.$route.params.locale} }">{{ $t('menu.terms') }}</router-link>
            <router-link :to="{ name: 'PrivacyView',  params: { locale: this.$route.params.locale} }">{{ $t('menu.privacy') }}</router-link>
            <router-link :to="{ name: 'CookiesView',  params: { locale: this.$route.params.locale} }">{{ $t('menu.cookies') }}</router-link>
            <router-link :to="{ name: 'HelpView',  params: { locale: this.$route.params.locale} }">{{ $t('menu.help') }}</router-link>
            <router-link :to="{ name: 'FAQView',  params: { locale: this.$route.params.locale} }">{{ $t('menu.FAQ') }}</router-link>
            <router-link :to="{ name: 'SupportView',  params: { locale: this.$route.params.locale} }">{{ $t('menu.support') }}</router-link>
            <a href="tg" class="social__link telegram-icon-lines"></a>
            <a href="youtube" class="social__link youtube-icon-lines"> </a>
          </div>
        </div>
      </div>
      </transition>
    </div>
  </header>
</template>

<script>

export default {
  name: "HeaderComponent",
  components: {},
  data() {
    return {
      menuActive: false,
    }
  },
  methods: {
    beforeEnter() {
    },

  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  position: absolute;
  z-index: 9;
  &__wrapper {

    max-width: 1140px;
    width: 100%;
    max-height: 100px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .3s ease-in-out;
    @media all and (max-width: 1140px){
      max-width: calc(100% - 20px);
      transition: all .3s ease-in-out;
    }
    @media all and (max-width: 600px){
      max-width: calc(100% - 20px);
      justify-content: center;
      transition: all .3s ease-in-out;
    }
    .logo {
      max-height: 48px;
      height: 48px;
      margin: auto 0;

      &--full {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }
  }


  .top-menu {
    display: flex;
    align-items: center;

    .top-menu__item {
      margin-right: 30px;

      img {
        filter: invert(1);
        transition: filter 400ms cubic-bezier(0.23, 1, 0.32, 1);
      }
      &:hover{
        img{
          filter: invert(.5);
          transition: filter 400ms cubic-bezier(0.23, 1, 0.32, 1);
        }
      }


      .divider {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.1px;
        color: #A4A6B5
      }
    }
  }
}
.menu__wrapper {
  width: 100%;


  .navbar {

    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;


    .menu-title {
      font-family: 'Raleway', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 52px;
      line-height: 61px;
      letter-spacing: -0.1px;
      color: #222E37;
      padding-top: 64px;
    }

    .menu-items {
      padding-top: 40px;
      padding-bottom: 110px;

      .navbar-nav {
        display: flex;
        flex-direction: column;
        .menu-item{
          font-family: 'Raleway', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 38px;
          color: #222E37;
          margin: 25px 0;
          text-decoration: none;
         // width: min-content;
          border-bottom: 2px solid transparent;
          transition: all .3s cubic-bezier(0.23, 1, 0.32, 1);
          &:hover{
            color: #0A7CFF;
            border-bottom: 2px solid #0A7CFF;
            transition: all .3s cubic-bezier(0.23, 1, 0.32, 1);
          }
        }
      }

    }

    .menu-bottom{
      display: flex;
      flex-direction: column;
      &__middle{
        display: flex;
        justify-content: space-between;
      }
      &__contacts{
        display: flex;
        &__email{
          margin-left: 86px;
        }
        .menu-bottom-text{
          font-family: 'Proxima Nova', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 27px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #222E37;
        }
        .menu-link{
          font-family: 'Raleway', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 130%;

          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #0A7CFF
        }
      }
      &__footer{
        padding-top: 64px;
        padding-bottom: 74px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        a{
          font-family: 'Raleway', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.1px;
          text-decoration-line: underline;
          color: #222E37;
          opacity: 0.7;
        }
      }
    }
  }
}



.global-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(1,1,1,.77);
  z-index: 50;
}

@media only screen and (min-width: 992px) {

}


@media only screen and (max-width: 991px) {
  .navbar {
    z-index: 100;
  }

  #navbarNav {

  }
}
</style>