<template>

  <agile :options="sliderOptions" ref="carousel" @after-change="showCurrentSlide()">
    <div v-for="(item, index) in items" :key="index"  class="slide-container">
      <div class="slide" :class="{[item.bgclass]: true}">
        <div class="slide__wrapper">
          <div class="slide__wrapper--main">
            <div class="slide__information">
              <div class="trainer-item__tag-section">
                <div class="trainer-item__tag">
                  {{ item.subtitle }}
                </div>
              </div>
              <h1 class="slide-title">{{ item.title }}</h1>
              <div class="information-button">
                <a href="#contact-form" class="btn btn-full">Связаться с нами</a>
                <a href="#" @click="openModal(item.video)" class="btn btn-link mobile-video">Подробнее</a>
              </div>
            </div>

            <div class="slide__video">
              <a href="#" @click="openModal(item.video)"  class="thumb">
                <div class="thumb-image" :class="{[item.thumb]: true}">
                </div>
              </a>
            </div>
          </div>



          <div class="slide__bottom">
            <div class="slide__bottom--button">

              <a href="#" @click="prevSlide" class="btn-square btn-prev">&lt;</a>
              <span class="current-slide">{{ currentSlide }}</span><span>/</span><span class="total-slide">{{ items.length }}</span>
              <a href="#" @click="nextSlide" class="btn-square btn-next">&gt;</a>
            </div>


          </div>
        </div>





      </div>
    </div>
  </agile>


  <modal-video-application-component  class="video" ref="modal"/>

</template>

<script>
import { VueAgile } from 'vue-agile'
import ModalVideoApplicationComponent from "@/components/ModalVideoApplicationComponent";

export default {
  name: "SliderOnMainComponent",
  components: {
    agile: VueAgile,
    ModalVideoApplicationComponent
  },
  methods: {
    openModal(video) {
      console.log(video)
      this.$refs.modal.video = video
      this.$refs.modal.show = true
    },
    nextSlide(){
      this.$refs.carousel.goToNext()
      this.currentSlide = this.$refs.carousel.getCurrentSlide()
      this.currentSlide++
    },
    prevSlide(){
      this.$refs.carousel.goToPrev()
     /* this.currentSlide--
      if(this.currentSlide <= 0) this.currentSlide = this.items.length
      if(this.currentSlide > this.items.length) this.currentSlide = 1*/
      this.currentSlide = this.$refs.carousel.getCurrentSlide()
      this.currentSlide++

    },
    showCurrentSlide()
    {
      this.currentSlide = this.$refs.carousel.getCurrentSlide()
      this.currentSlide++
    }

  },

  data() {
    return {
      currentSlide: 1,
      sliderOptions: {
        navButtons: false,
        autoplay: true,
      },

      items:
          [
            {
              id: 1,
              video: "https://www.youtube.com/embed/NK9wzOvcOOc?si=M3xcyWOvG736xrOt",
              title: "Камера запуска и приема средств очистки и диагностики",
              subtitle: "Цифровая экосистема в нефтегазовом деле",
              bgclass: "sim-1",
              thumb: "sim-thumb-1"
            },
            {
              id: 2,
              video: "https://www.youtube.com/embed/YqVJb_fIBfI?si=VhNZ4qfy9CYonJDz",
              title: "Замер сопротивления грунтов",
              subtitle: "Цифровая экосистема в нефтегазовом деле",
              bgclass: "sim-2",
              thumb: "sim-thumb-2"
            },
            {
              id: 3,
              video: "https://www.youtube.com/embed/67TNQn33H5M?si=q-K91mdsdbazy_JU",
              title: "Геодезическое обеспечение СНГО",
              subtitle: "Цифровая экосистема в нефтегазовом деле",
              bgclass: "sim-3",
              thumb: "sim-thumb-3"
            },
            {
              id: 4,
              video: "https://www.youtube.com/embed/hjQK_SC6K-8?si=6cv606YOKhzNjdeN",
              title: "Диагностика электрических машин",
              subtitle: "Цифровая экосистема в энергетике",
              bgclass: "sim-4",
              thumb: "sim-thumb-4"
            },
            {
              id: 5,
              video: "https://www.youtube.com/embed/rhiqnK-x9kg?si=J8VCorauC6joSUm2",
              title: "Тепловой пункт",
              subtitle: "Цифровая экосистема в энергетике",
              bgclass: "sim-5",
              thumb: "sim-thumb-5"
            },
            {
              id: 6,
              video: "https://www.youtube.com/embed/gSL8qGU039w?si=RzqkYJLNzW9qKmNG",
              title: "Тепловизионная диагностика высоковольтного оборудования подстанции",
              subtitle: "Цифровая экосистема в энергетике",
              bgclass: "sim-6",
              thumb: "sim-thumb-6"
            },
            {
              id: 7,
              video: "https://www.youtube.com/embed/0vjEU28vZkc?si=oW2IuCrVzRSg2_Bb",
              title: "Газотурбинная установка",
              subtitle: "Цифровая экосистема в энергетике",
              bgclass: "sim-7",
              thumb: "sim-thumb-7"
            },
            {
              id: 8,
              video: "https://www.youtube.com/embed/EyWPgak0yBA?si=5WGzWFABdksEzAPg",
              title: "Регенеративные подогреватели низкого давления",
              subtitle: "Цифровая экосистема в энергетике",
              bgclass: "sim-8",
              thumb: "sim-thumb-8"
            },
            {
              id: 9,
              video: "https://www.youtube.com/embed/",
              title: "Теория автоматического управления",
              subtitle: "Цифровая экосистема в энергетике",
              bgclass: "sim-9",
              thumb: "sim-thumb-9"
            },
            {
              id: 10,
              video: "https://www.youtube.com/embed/",
              title: "Электробезопасность",
              subtitle: "Цифровая экосистема в энергетике",
              bgclass: "sim-10",
              thumb: "sim-thumb-10"
            },
            {
              id: 11,
              video: "https://www.youtube.com/embed/xyGniBwvyGs?si=PZiOIpooa-skIWx7",
              title: "Электромонтер",
              subtitle: "Цифровая экосистема в энергетике",
              bgclass: "sim-11",
              thumb: "sim-thumb-11"
            },
            {
              id: 12,
              video: "https://www.youtube.com/embed/s6v8agRkuFk?si=12kmBIEVNFZ0ERMa",
              title: "Электроснабжение промышленных предприятий",
              subtitle: "Цифровая экосистема в энергетике",
              bgclass: "sim-12",
              thumb: "sim-thumb-12"
            },
            {
              id: 13,
              video: "https://www.youtube.com/embed/03zoIHXWA8c?si=a6hZjpmrp9Fn8VVK",
              title: "Вывод из работы и ввод в работу автотрансформатора 220/110 кВ на подстанции",
              subtitle: "Цифровая экосистема в энергетике",
              bgclass: "sim-13",
              thumb: "sim-thumb-13"
            },
            {
              id: 14,
              video: "https://www.youtube.com/embed/4G0kTlkFExw?si=nLCZfrGIonfmhc4U",
              title: "Оператор ХимВодоподготовки",
              subtitle: "Цифровая экосистема в энергетике",
              bgclass: "sim-14",
              thumb: "sim-thumb-14"
            },
            {
              id: 15,
              video: "https://www.youtube.com/embed/G2wbwSdDs1c?si=O8q04MsdfJuOlClu",
              title: "Оперативные переключения на подстанции",
              subtitle: "Цифровая экосистема в энергетике",
              bgclass: "sim-15",
              thumb: "sim-thumb-15"
            },
            {
              id: 16,
              video: "https://www.youtube.com/embed/",
              title: "Электротехническая лаборатория (ШР, заземление, молниеотводы, качество ЭЭ) (низковольтная)",
              subtitle: "Цифровая экосистема в энергетике",
              bgclass: "sim-16",
              thumb: "sim-thumb-16"
            },
            {
              id: 17,
              video: "https://www.youtube.com/embed/M9bYcxUgK98?si=0JdTLKmOjo-5Luv4",
              title: "Электромагнитная совместимость оборудования подстанции",
              subtitle: "Цифровая экосистема в энергетике",
              bgclass: "sim-17",
              thumb: "sim-thumb-17"
            },
            {
              id: 18,
              video: "https://www.youtube.com/embed/mwphITfEzDo?si=elNmYQlOrsf0--0-",
              title: "Насосная установка",
              subtitle: "Цифровая экосистема в энергетике",
              bgclass: "sim-18",
              thumb: "sim-thumb-18"
            },
            {
              id: 19,
              video: "https://www.youtube.com/embed/hfZn5MDmhRg?si=PgJq7s4U08Ks3lg8",
              title: "Диагностика объектов и оборудования трубопроводного транспорта",
              subtitle: "Цифровая экосистема в нефтегазовом деле",
              bgclass: "sim-19",
              thumb: "sim-thumb-19"
            },
            {
              id: 20,
              video: "https://www.youtube.com/embed/9BcletIZgs8?si=Xf-HLzZDLmwpqAq9",
              title: "Машины и оборудование нефтегазовых объектов",
              subtitle: "Цифровая экосистема в нефтегазовом деле",
              bgclass: "sim-20",
              thumb: "sim-thumb-20"
            },
            {
              id: 21,
              video: "https://www.youtube.com/embed/Q53mIjAVqWM?si=ZxSVG2mH1Tt5Gki_",
              title: "Техническое обслуживания и ремонт нефтегазопровода",
              subtitle: "Цифровая экосистема в нефтегазовом деле",
              bgclass: "sim-21",
              thumb: "sim-thumb-21"
            }
      ],

    }
  }


}

</script>

<style   lang="scss">
.agile{
  overflow: hidden;
}
.slide-container{
  width: 100%;
}

.slide{
  height: 100vh;

  &__wrapper{
    position: relative;
    height: inherit;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    align-items: center;

    width: initial;

    &--main{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
  .agile{
    overflow: hidden;
  }
  &__information{
    width: 70%;
    transition: all .3s ease-in;
    @media screen and (max-width: 720px){
      width: 90%;
      margin: auto;
      text-align: center;
      transition: all .3s ease-in-out;
    }
    .trainer-item__tag-section{
      display: flex;
      justify-content: flex-start;
      padding-bottom: 24px;
      transition: all .3s ease-in;
      @media screen and (max-width: 720px){
        justify-content: center;
        transition: all .3s ease-in-out;
      }
      .trainer-item__tag{
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        color: #FFFFFF;
        text-align: center;
        padding: 2px 12px;
        mix-blend-mode: normal;
        border: 1px solid #A4A6B5;
        border-radius: 100px;
        @media screen and (max-width: 720px){
          font-size: 18px;
          transition: all .3s ease-in-out;
        }
      }
    }
    .slide-title{
      font-family: 'Proxima Nova', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
      letter-spacing: 1px;
      color: #FFFFFF;
      @media screen and (max-width: 720px){
        font-size: 32px;
        transition: all .3s ease-in-out;
      }
    }
    .information-button {
      padding-top: 52px;
      display: flex;
      align-items: center;
      .mobile-video{
        display: none;
        @media screen and (max-width: 720px){
          display: block;
          transition: all .3s ease-in-out;
        }
      }
      @media screen and (max-width: 720px){
        justify-content: center;
        transition: all .3s ease-in-out;
      }
      a:first-child{
        margin-right: 46px;
        @media screen and (max-width: 720px){
          margin-right: 0;
          transition: all .3s ease-in-out;
        }
      }

    }

  }
  &__video{
    max-width: 270px;
    height: 360px;
    width: 100%;

    display: block;
    @media screen and (max-width: 720px){
      display: none;
    }
    .thumb{
      .thumb-image{
        width: 100%;
        height: 100%;
        background: rgba(24, 26, 71, 0.3);
        border-radius: 4px;
        position: relative;
        transition: background .3s ease-in-out;
        &:after{
          content: url("@/assets/images/icons/play-white.svg");
          position: absolute;
          top: calc(100% / 2 - 63px / 2);
          left: calc(100% / 2 - 63px / 2);
          opacity: .8;
        }
      }

      &:hover{
        .thumb-image{
          background: rgba(24, 26, 71, 0.75);
          transition: background .3s ease-out;
          &:after{
            content: url("@/assets/images/icons/play-white.svg");
            position: absolute;
            top: calc(100% / 2 - 63px / 2);
            left: calc(100% / 2 - 63px / 2);
            opacity: 1;
          }
        }



      }
    }

  }
  &__tagline{
    padding-top: 45px;
    display: flex;
    flex-direction: row;
    .tag{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 18px 32px 18px 29px;
      margin-right: 40px;
      border: 1px solid #24C3F3;
      border-radius: 15px;
      background: rgba(0, 0, 0, 0.5);
      &__item{
        font-family: 'Geometria';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        color: #FFFFFF;
      }
      &__icon{
        width: 37px;
        height: 37px;
        object-fit: contain;
        margin-right: 20px;
      }
    }
  }
  &__bottom{

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    @media all and (max-width: 1023px) {
      align-items: center;
      justify-content: center;
    }
    &--button{
      display: flex;
      align-items: center;
      .current-slide{
        font-weight: bold;
        color: #fff;
        font-size: 20px;
        margin-left: 20px;
        letter-spacing: 2px;
      }
      .total-slide{
        font-size: 14px;
        margin-right: 20px;
      }
      span{
        font-family: "Raleway", sans-serif;
        line-height: 24px;
        font-size: 18px;
        color: #a4a6b5;
      }
    }

  }


  .btn-square{
    width: 50px;
    height: 50px;
    border-radius: 8px;
    border: 1px solid transparent;
    color: #fff;
    background: rgb(48 48 49);
    text-align: center;
    line-height: 48px;
    border-image: linear-gradient(to bottom right, #7f7f80, #2e2e2f);
    border-image-slice: 1;
    font-size: 24px;
    display: block;
    text-decoration: none;
  }
}



</style>