import { createApp } from 'vue'

import App from './App.vue'

import { setupRouter } from "@/router";

import { setupI18n } from "@/i18n";
import en from "@/locales/en.json";
import ru from "@/locales/ru.json";




//Подключим файлы компонентов
import LandingLayout from "@/layouts/LandingLayout";

// Подключим стили
import '@/assets/scss/dive.scss'
const i18n = setupI18n({
    globalInjection: true,
    legacy: false,
    locale: 'ru',
    fallbackLocale: 'en',
    messages: {
        en,
        ru,
    }
});

const router = setupRouter(i18n);

createApp(App)
    .use(i18n)
    .use(router)
    .component('landing-layout', LandingLayout)
    .mount('#app')
